.item{
  .title{
    font-size: 12px;
    line-height: 16px;
    margin: 16px 0;
  }
  .value{
    font-weight: 500;
    font-size: 44px;
    line-height: 53px;
  }
  .subtitle{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

  }
}