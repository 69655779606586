@import "../../styles/variables";
// styling for import form, the form should be centered and have a max width of 500px
// with a light rounded 1px border, white background and light grey shadow.

.importForm{
  width: 100%;
  margin-top: 1rem;

  .importForm__header {

  }

  // The body should be centered with a max width of 500px, white background and light grey shadow.
  .importForm__body {
    max-width: 500px;
    margin: 0 auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    padding: 1rem;
    box-sizing: border-box;
  }

  .importForm__file {
    margin-bottom: 0;
  }

  .importForm__item {
    margin-bottom: 1rem;
  }
  .importForm__item__label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .importForm__item__input {
    cursor: pointer;
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .importForm__buttons {
    display: flex;
    justify-content: flex-end;
  }
  .importForm__button {
    margin-left: 1rem;
    &:active {
      box-shadow: none;
    }
  }
  .importForm__button__cancel {
    background-color: $color-grey;
    color: $font-color-dark-grey;
    box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.2);

  }
  .importForm__button__import {
    background-color: $color-submit;
    color: white;
    box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.2);
  }

  .importForm__item__error {
    color: $color-cancel;
    font-size: 0.8rem;
  }
}