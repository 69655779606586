.wrapper {
  flex-grow: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unresolved {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 18px;

  .item {
    display: flex;
    align-items: center;

    .itemNumber {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
    }

    .itemTitle {
      font-size: 12px;
      line-height: 16px;
    }
  }

  * {
    margin-left: 6px;
  }

}


.ttdr {
  margin-left: 20px;
  display: flex;
  align-items: center;

  img {
    margin-left: 12px;
    margin-right: 4px;
  }

  .ttdrTitle {
    font-size: 12px;
    line-height: 16px;
  }

  .ttdrTime{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
}