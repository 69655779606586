.wrapper {
  padding: 1rem;
  height: 100%;
  box-sizing: border-box;
}

.table {
  font-size: 12px;
  line-height: 16px;
  background-color: white;
}

.headers {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 7px 7px 7px 24px !important;
}

.rows {
  //padding: 7px 7px 7px 24px !important;
  //margin: 15px 0;
  //height: 40px;
}

.center {
  text-align: center;

  button {
    margin: 0 !important;
  }
}

.published {
  background-color: #FF6962;

  &.green {
    background-color: #77DD76;
  }
}