.header--main {
  width: 100%;
  /* height: 71px; */
  box-sizing: border-box;
  height: 100%;
  position: absolute;
}

.header--content {
  background-color: #092247;
  height: 71px;
  display: flex;
  color: white;
  padding: 16px;
  box-sizing: border-box;
}

.image {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 40px;
    width: inherit;
  }
}

.title {
  margin: 0 16px;
  h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  .subtitle {
    font-size: 12px;
    line-height: 15px;
    vertical-align: top;
    cursor: pointer;
  }
}

.handle {
  cursor: pointer;
  width: 66px;
  height: 20px;
  border-radius: 10px;
  background-color: #092247;
  margin: -10px auto;
  position: relative;
  z-index: 10;

  .handle__line {
    position: absolute;
    width: 26px;
    border: 2px solid #C5CADA;
    border-radius: 2px;
    margin: 0 auto;
    top: 10px;
    left: 18px;
  }
}
.secHandle {
  cursor: pointer;
  width: 66px;
  height: 20px;
  border-radius: 10px;
  background-color: #C5CADA;
  margin: -10px auto;
  position: relative;
  z-index: 10;

  .handle__line {
    position: absolute;
    width: 26px;
    border: 2px solid #092247;
    border-radius: 2px;
    margin: 0 auto;
    top: 10px;
    left: 18px;
  }
}