.button {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid white;
  color: white;
  width: 132px;
  height: 40px;
  box-shadow: none;
  margin:0 10px;

  &.changePassword {
    width: 180px;
  }

}


.changePasswordModal {
  background-color:white;
  width: 200px;
}
