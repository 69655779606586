@import "../../styles/variables";

.wrapper {
  padding: 1rem;
  height: 100%;
  width: 90%;
  margin: auto;
  box-sizing: border-box;

  thead {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  tbody {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}


.form-group {
  display: flex;
  align-items: center;
  margin: 15px auto;

  label {
    margin-right: 4px;
  }


  .recommendation__textarea {
    min-width: 300px;
    margin: 0 15px;
  }

  .form-group__input {

  }
}

.form-group__input-table {
  width: 400px;

  .cell-style__header {
    background-color: lightgray !important;
    font-weight: bold;
  }

  td {
    text-align: left !important;
    padding: 0 4px !important;
  }

  text-align: start !important;

  input {
    text-align: start !important;
  }
}


.inputField {

}