@import "../../../../styles/variables";

.table--header {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.table--body{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $font-color-dark-grey
}

.column--headers {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 7px 7px 7px 24px !important;
}

.row--style {
  padding: 7px 7px 7px 24px !important;
  margin: 10px 0;
  white-space: normal !important;

  &.topic {
    word-wrap: break-word;
    white-space: break-spaces;
  }

  .risk {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }

  .impact {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
}

.table--style {
  font-size: 12px;
  line-height: 16px;
  background-color: white;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}