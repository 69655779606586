.wrapper {
  display: flex;
  margin: 50px 0;
  justify-content: space-evenly;

}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .legend__item {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #C5CADA;

    .number {
      font-size: 16px;
      line-height: 19px;
    }

    > * {
      margin: 0 6px;
    }
  }
}

.score {
  width: 100px;
  height: 100px;
  color: white;

  .num {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 53px;
    letter-spacing: -0.02em;
  }

  .subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
}

