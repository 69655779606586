.wrapper {

}

.header {

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #C5CADA;
  }
}

.main {

}
