.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-grow: 1;

  button {
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    width: 132px;
    height: 40px;
    margin: 0 !important;
    box-shadow: none;
  }

  .backBtn {
    width: auto;
    padding: 0 10px;
  }
}