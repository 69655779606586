.topic {
  width: 10%;
}

.description {
  width: 20%;
}

.solution {
  width: 20%;
  padding: 0 !important;
  position: relative;
}

.note {
  width: 20%;
  padding: 0 !important;
  position: relative;
}

.compliance {
  select {
    width: 150px !important;
  }
}

.risk {
  select {
    width: 150px !important;
  }
}

.impact {
  width: 10%;
}