.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: #616161;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.control select {
  display: block;
  width: 20rem;
  //margin: auto;
  border-radius: 4px;
  padding: 0.25rem;
  border: 1px solid #ccc;
}

.control small{
  display: block;
  font-size: 0.75rem;
  margin-top: -0.75rem;
}
