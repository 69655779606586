.score {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */


    /* System - Gray */

    color: #5F6060;
  }

  .score--value {
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 53px;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;

    .chevron {
      margin: 6px;
      font-size: 12px;
      font-weight: 400;

      &.up {
        color: #27AE6D;
      }

      &.down {
        color: #DA2E49;
      }
    }
  }
}