/**
 * Created by Constantine Kuks on 18/05/2021.
 */

.logo img{
    display: block;
    width: 40px;
}

.navbar {
    height: 84px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #092247;
}

.content {
    height: 84px;
    display: flex;
    align-items: center;
}

.nav-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.nav-items ul li{
    display: inline;
    margin: 0 15px;
}

.nav-items, .nav-items ul, .nav-items ul li, .nav-items ul li a {
    display: inline-block;
    /*height: 100%;*/
}

.nav-items ul li a {
    vertical-align: middle;
    line-height: 50px;
    box-sizing: border-box;
    padding: 0 15px;
}

.link {
    text-decoration: none;
    font-size: 16px;
    color:white;
}

.auth {
    margin-left: auto;
}

.auth button {
    height: 30px;
    width: 97px;
    font-weight: 500;
}
.auth a {
    margin: 16px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    font-weight: 500;
}
