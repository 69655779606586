.card {
  width: 100%;
  max-width: 790px;
  //height: 200px;
  background: #F8F9FF;
  box-shadow: 0px 5px 20px rgba(220, 229, 255, 0.5);
  overflow: hidden;

  .card--header {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;

    background: #FBFCFD;
    border-radius: 4px;
    box-shadow: 0px 5px 20px rgba(220, 229, 255, 0.5);
    padding: 5px;

    h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;

      color: #000000;
    }

    > * {
      margin: 5px;
    }

    .expander {
      margin-inline-start: auto;
      cursor: pointer;
    }

  }

  .card--subheader {
    height: 140px;
    margin: 0;
    padding: 16px 16px 0;
    display: flex;

    &.open {
      height:unset;
      margin-bottom: 20px;
    }

    .card--subheader--score {
      flex-grow: 1;
    }


    .card--subheader--recommendations {
      flex-grow: 4;
    }
  }

  .card--table--container {

    height: 0;

    &.expand {
      height: 400px;
    }

    .card--table--header {

    }
    .card--table {

    }
  }
}