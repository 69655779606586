.form {
  max-width: 500px;
  button {
    margin: 10px 0 !important;
  }
}

.wrapper {
  max-width: 500px;
}

