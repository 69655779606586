.score {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #5F6060;
  }

  .legend {
    display: flex;
    justify-content: space-evenly;

    .item {
      margin: 0 8px;
      display: flex;
      align-items: center;

      img {
        margin: 8px;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #5F6060;
      }

    }

  }
}


