@import "./_variables.scss";

input[type="password"], input[type="text"], button, textarea {
  border-radius: 5px;
  padding: 5px;
  margin: 0 5px 15px 5px;
  background-color: white;
  border: 1px solid grey;
  box-shadow: 0px 5px 20px rgba(220, 229, 255, 0.5);
}
input::placeholder, textarea::placeholder {
  color: $font-color-bright-grey;
}

button {
  cursor: pointer;
}