.backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    z-index:0;
}

.dropdown {
    position: absolute;
    background-color: #153d72;
    border-radius: 4px;
    box-shadow: 1px 1px 1px grey;
    padding: 0.5rem;
    z-index: 1;
    height: unset!important;
    max-height: 300px;
    overflow-y: scroll;

    a {
        text-decoration: none;
        display: block;
        color: white;
        &:visited {
            color: white;
        }
    }

}