@import "../../../../styles/variables";
.recommendation {

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #5F6060;
  }

  .recommendation--box {
    /* bright offwhite */
    margin-top: 12px;
    background: #FBFCFD;
    border-radius: 4px;
    filter: drop-shadow(0px 3px 12px rgba(9, 34, 71, 0.1));
    padding: 15px;
    //max-height: 92px;
    box-sizing: border-box;

    .recommendation--box--scrollbar {
      max-height: 62px;
      &.open{
        max-height: unset;
      }
    }

    .recommendation--box--header {
      display: flex;
      align-items: center;
      .title {
        display: inline-block;
        margin: 0;
        margin-inline-start: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #092247;
      }
    }

    .recommendation--box--text {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin-top: 8px;
      //max-height: 100px;
      white-space: pre-line;
      /* or 133% */


      /* System - Gray */

      color: $font-color-dark-grey;
    }
  }
}

