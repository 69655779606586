.hint {

  display: inline-block;
  position: relative;
  margin: 0 4px;
  min-width: 160px;
  .hint--text {
    display: none;
    //background: #FBFCFD;
    background: rgb(9, 34, 71);
    //color: #5F6060;
    color: white;
    box-shadow: 0px 2px 6px rgba(9, 34, 71, 0.25);
    border-radius: 5px;
    min-width: 80px;
    max-width: 150px;
    padding: 4px;
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    z-index: 10;

    top:24px;

    &.top {
      bottom: 20px;
      transform: translate(-50%, 0);
    }

    &.bottom {
      top: 20px;
      transform: translate(-50%, 0);
    }

    &.left {
      right: 20px;
      transform: translate(0, -50%);
    }

    &.right {
      left: 20px;
      transform: translate(0, -50%);
    }
  }

  &:hover {
    .hint--text {
      display: inline-block;
    }
  }
}


