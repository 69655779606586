.legend {
    display: flex;
    width: 484px;
    height: 35px;
}

.legend .legend__item {
    color: #5F6060;
    font-size: 12px;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.legend__item .legend__item__info{
    color: #5F6060;
    flex-grow: 1;
}


.legend .legend__item .icon {
    margin: 0 12px;
}

.legend .legend__item .description {
    width: unset;
}

.legend .legend__item .value {
    font-size: 16px;
    font-weight: 500;
    color:black;
}