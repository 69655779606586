.wrapper {
  background-color: #051730;
  height: 0;
  position: relative;
  width: 100%;
  //top: 71px;
  z-index: 1;
  //transition: height 0.5s;
  display: flex;
  align-items: center;
  //transition: all 0.5s;
  overflow: hidden;
}

@keyframes opening {
  0% {
    height: 0;
  }



  100% {
    height: calc(100% - 71px);
  }
}

@keyframes closing {


  0% {
    height: calc(100% - 71px);
  }
  100% {
    height: 0;
  }
}

.open {
  animation: opening 0.5s forwards ease-in-out;
  //height: calc(100% - 71px);
}

.close {
  animation: closing 0.5s forwards ease-in-out;
  //height: 0;
}

.leftPane {
  width: 40%;
  padding: 42px;
  box-sizing: content-box;
}

.divider {
  width: 0;
  border: 1px solid #C5CADA;
  height: 70%;
  margin: 0 48px;
}

.rightPane {
  width: 60%;
  padding: 42px;
  box-sizing: content-box;
}
