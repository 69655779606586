@import "../../../../styles/variables";
.filter {
  user-select: none;
  span {
    cursor: pointer;
  }

  .filter--list {
    .item {

    }
  }

  .backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    z-index:0;
  }
  .checkbox--list {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(9, 34, 71, 0.15);
    border-radius: 5px;
    position: absolute;
    z-index: 1;

    .search--icon {
      position: absolute;
      top: 13px;
      left: 13px;
      color: $font-color-bright-grey;
    }

    input {
      border: none;
      padding: 13px 13px 13px 32px;

      &::placeholder {
        color: $font-color-bright-grey;
      }
    }

    .list--container {
      max-height: 150px;
      overflow: auto;

      .item {
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        label {
          cursor: pointer;
        }

        &.title {
          border-bottom: #b8b8b8;
          cursor: default;

        }
      }
    }
  }

}