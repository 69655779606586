.backdrop {
  background-color: rgba(80, 80, 80, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}

.modal {
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  position: fixed;
  border-radius: 8px;
  padding: 1rem;
  z-index: 1;
}

.close {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 10px;
  border-radius: 5px;
  padding: 5px;
  height:20px;
  width:20px;
  margin: 0 5px 15px 5px;
  background-color: white;
  border: 1px solid grey;
  box-shadow: 0px 5px 20px rgba(220, 229, 255, 0.5);
  border: none;
}