.categories{
  color: white;

  margin: 20px 0;
  max-height: 200px;

  .scrollbar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 200px;
  }

  .categories__item {
    display: grid;
    margin: 4px 0;
    grid-template-columns: 50px 1fr;



  }
}