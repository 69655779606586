.custom-label {
  //transform: translate(-50%, -50%);
}

.custom-wrapper {
  height: 59px;
  width: 59px;
}

.dashed-lines {
  width: 286px;
  height: 286px;
}

.big-white-circle{
  width: 415px;
  height: 415px;
}
.inner-circle{
  width: 198px;
  height: 198px;
}
.shield{
  width: 148px;
  height: 176px;
}
.total-score{
  width: 90px;
  height: 80px;

  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 50.0677px;
  line-height: 61px;
  text-align: center;

}