$header-height: 71px;

.report {
  background-color: #F6F6F6;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.header {
  //height: $header-height;
  height: 100%;
  min-width: 1366px;
}

.body {
  display: flex;
  position: relative;
  height: calc(100% - #{$header-height});
  top: 71px;

  .left-pane {
    width: 40%;
    min-width: 550px;
    padding: 10px;
    display: flex;
    justify-content: center;

  }

  .right-pane {
    width: 60%;
    min-width: 800px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
}

