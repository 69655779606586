.container {
  display: flex;
  flex-direction: column;
  //justify-content: space-evenly;
  > img {
    margin: 16px;
    width: 127px;
  }

  > *:last-child {
    margin-top: auto;
    margin-bottom: 20px;
  }
}