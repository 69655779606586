@import "../../../../styles/variables";

.table {
  margin:auto;
  border-collapse: collapse;


  textarea{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    transform: translate(0, -50%);
    resize: none;
    border-radius: 0;
    border: none;

  }


  th, td {
    border: 1px solid $font-color-bright-grey;
    border-collapse: collapse;
    padding: 10px;
  }

}